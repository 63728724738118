import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import useTitleOperation from "src/pages/hooks/title-operation";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { menuTitleState } from "src/recoil/menuTitle/atom";
import UserLogin from "./userInfo/UserLogin";
import { useLocation } from "react-router-dom";

export const menuTitle = [
  {
    title: "대시보드",
    keyword: "main",
  },
  {
    title: "건물",
    keyword: "building",
  },
  {
    title: "워크오더",
    keyword: "workorder",
  },
  {
    title: "회원",
    keyword: "member",
  },
  {
    title: "상품",
    keyword: "product",
  },
  {
    title: "신청/계약",
    keyword: "contract",
  },
  {
    title: "정산 정보",
    keyword: "provider",
  },
  {
    title: "공용공간 예약",
    keyword: "reservation",
  },
  {
    title: "이용자",
    keyword: "user",
  },
  {
    title: "방문자",
    keyword: "visitor",
  },
  {
    title: "사업자",
    keyword: "org",
  },
  {
    title: "빌링 스케쥴",
    keyword: "billing",
  },
  {
    title: "관리비 부과항목관리",
    keyword: "chargeItems",
  },
  {
    title: "관리비 부과관리",
    keyword: "chargeMnt",
  },
  {
    title: "공지사항",
    keyword: "notice",
  },
  {
    title: "Iot 기기",
    keyword: "iot",
  },
  {
    title: "파트너",
    keyword: "partner",
  },
  {
    title: "파트너 회원",
    keyword: "partner-members",
  },
  {
    title: "출입장치 관리",
    keyword: "accessDevice",
  },
  {
    title: "출입그룹 관리",
    keyword: "accessGroup",
  },
  {
    title: "메세지 발신 이력",
    keyword: "message",
  },
  {
    title: "공용공간 요금",
    keyword: "price",
  },
  {
    title: "VoC 항목",
    keyword: "voc",
  },
  {
    title: "CS",
    keyword: "question",
  },
  {
    title: "CCTV 장치",
    keyword: "cctv",
  },
  {
    title: "CCTV 이벤트",
    keyword: "cctvEvent",
  },
  // {
  //   title: "팝빌 연동 설정",
  //   keyword: "popbill",
  // },
];

export const GlobalNav = () => {
  // html title 바인딩
  useTitleOperation();
  // 메뉴 타이틀 상태관리
  const location = useLocation();
  const { pathname } = location;
  const setMenuTitle = useSetRecoilState(menuTitleState);
  const title = useRecoilValue(menuTitleState);
  const nowMenuURL = location.pathname.split("/")[3];

  useEffect(() => {
    if (nowMenuURL) {
      const findNowMenu = menuTitle.find((el) => el.keyword === nowMenuURL);
      findNowMenu ? setMenuTitle(findNowMenu.title) : setMenuTitle("");
    } else {
      setMenuTitle("");
    }
  }, [nowMenuURL, setMenuTitle]);

  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return null;
  }

  return (
    <header className="global-nav">
      <h1>{title}</h1>
      <UserLogin />
    </header>
  );
};
